exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adults-js": () => import("./../../../src/pages/adults.js" /* webpackChunkName: "component---src-pages-adults-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-children-js": () => import("./../../../src/pages/children.js" /* webpackChunkName: "component---src-pages-children-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ergotherapy-js": () => import("./../../../src/pages/ergotherapy.js" /* webpackChunkName: "component---src-pages-ergotherapy-js" */),
  "component---src-pages-evaluation-js": () => import("./../../../src/pages/evaluation.js" /* webpackChunkName: "component---src-pages-evaluation-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-logotherapy-js": () => import("./../../../src/pages/logotherapy.js" /* webpackChunkName: "component---src-pages-logotherapy-js" */),
  "component---src-pages-methods-js": () => import("./../../../src/pages/methods.js" /* webpackChunkName: "component---src-pages-methods-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-parents-js": () => import("./../../../src/pages/parents.js" /* webpackChunkName: "component---src-pages-parents-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-teenagers-js": () => import("./../../../src/pages/teenagers.js" /* webpackChunkName: "component---src-pages-teenagers-js" */),
  "component---src-templates-adults-adult-page-js": () => import("./../../../src/templates/adults/adult-page.js" /* webpackChunkName: "component---src-templates-adults-adult-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-children-children-page-js": () => import("./../../../src/templates/children/children-page.js" /* webpackChunkName: "component---src-templates-children-children-page-js" */),
  "component---src-templates-faq-post-js": () => import("./../../../src/templates/faq-post.js" /* webpackChunkName: "component---src-templates-faq-post-js" */),
  "component---src-templates-method-post-js": () => import("./../../../src/templates/method-post.js" /* webpackChunkName: "component---src-templates-method-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-parents-parent-page-js": () => import("./../../../src/templates/parents/parent-page.js" /* webpackChunkName: "component---src-templates-parents-parent-page-js" */),
  "component---src-templates-program-post-js": () => import("./../../../src/templates/program-post.js" /* webpackChunkName: "component---src-templates-program-post-js" */),
  "component---src-templates-team-post-js": () => import("./../../../src/templates/team-post.js" /* webpackChunkName: "component---src-templates-team-post-js" */),
  "component---src-templates-teenagers-teenager-page-js": () => import("./../../../src/templates/teenagers/teenager-page.js" /* webpackChunkName: "component---src-templates-teenagers-teenager-page-js" */)
}

